import React, { useState } from 'react';
import axios from 'axios';
import { loginUser, preloadMagic } from '../services/magic.js';

import { Button, Form, Container, Row, Col, Image } from 'react-bootstrap';

import QualtricsLogo from '../assets/QualtricsXM_WHITE.png';


function Login(props) {

    preloadMagic();//Preload Magic Auth Iframe

    const [validated, setValidated] = useState(),
        [isPreapproved, setPreapproved] = useState(),
        [isLoading, setLoading] = useState(false),
        [value, setValue] = useState(``);//State for this component
    
    const onInput = ({ target: { value } }) => setValue(value);//On form input update state

    const onFormSubmit = e => {//On form submit, validate and attempt login
        const form = e.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (form.checkValidity() === true) {
            e.preventDefault();
            setLoading(true);
            checkUserPreapproval(value)
        }
    };

    async function checkUserPreapproval(email) {//Checks a user's preapproval to access the dashboard
        if(isPreapproved === false){
            setPreapproved(null);
        }
        try {
            await axios.get('/api/API-Server',{//Get user pre-approval
                params: {
                    requestType: 'get-user',
                    email: email
                }
            });
            userLogin(email);
        } catch(error){//if not pre-approved show error
            setPreapproved(false);
            setLoading(false);
        }
    };

    async function userLogin(email){
        try {
            await loginUser(email);
            props.validateUser();//Now that the user is login, run the validation to set the user state
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }
    

    return (
        <Row className="mx-auto w-100 h-100 d-flex text-center">
            <div className="d-xs-inline-block d-md-none pb-0" style={gradientStrip}></div>
            <Col className="d-flex h-100">
                <Container className="my-auto w-100">
                        <h1 className="mb-0 py-5 display-5 font-weight-bold" style={{color: '#000'}}>Log in to your dashboard</h1>
                    <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="sr-only">Email address</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="hello@example.com"
                                onChange={onInput}
                                value={value}
                                style={formControl}
                                className="mx-auto"
                            />
                        </Form.Group>
                        <Button 
                            variant="primary" 
                            type="submit"
                            style={{padding: '15px', maxWidth:350, borderRadius: '.6rem', borderTopRightRadius: 0, borderBottomLeftRadius: 0, background: '#00b4ef', border: '1px solid #00b4ef'}}
                            className="w-100 my-2"
                            disabled={isLoading || props.loggingIn}
                        >
                            {isLoading ? 'Loading...' : 'Log in'}
                        </Button>
                    </Form>
                    {props.invalidToken ? <p className="text-danger"><small>There was an issue logging you in, please try again</small></p> : null}
                    {isPreapproved === false ? <p className="text-danger"><small>Your email is not authorized to access this dashboard</small></p> : null}
                    {props.loggingIn === true ? <p className="text-info"><small>Logging in, please wait...</small></p> : null}
                </Container>
            </Col>
            <Col className="d-none d-md-flex" style={gradientBackground}>
                <Row className="my-auto mx-auto" style={{maxWidth: 800}}>
                    <Image 
                        src={QualtricsLogo}
                        className="img-fluid my-auto mx-auto p-3"
                    ></Image>
                </Row>
            </Col>
        </Row>
    )
};

const gradientBackground = {
    background: 'linear-gradient(to right, #21dbaa,#00b4ef,#0768dd,#5f1ae5)'
}

const gradientStrip = {
    height: 10,
    width: '100%',
    background: 'linear-gradient(to right, #21dbaa,#00b4ef,#0768dd,#5f1ae5)'
}

const formControl = {
    border: '1px solid black',
    maxWidth: 350
}


export default Login;
