import React from "react";
import {
    Image,
    Navbar,
    Button,
} from 'react-bootstrap';
import { logoutUser } from '../services/magic.js';

import Approvals from '../components/Approvals.js';

import QualtricsLogo from '../assets/QualtricsXM_WHITE.png';


function Dashboard(props){

    function logout(){
        logoutUser();
        props.setUser({isLoggedIn: false});
    }
        return(
            <div className="w-100 h-100" style={{background: '#f2f6f9'}}>
                <Navbar style={{ background: '#000000' }} className="py-2 px-3">
                    <Navbar.Brand>
                        <Image
                            src={QualtricsLogo}
                            width="110"
                            height="29"
                            className="d-inline-block align-top"
                            alt="Qualtrics XM Logo"
                        />
                        <Button
                            className="navbar-toggler position-absolute d-md-none collapsed"
                            type="button"
                        >
                        </Button>
                    </Navbar.Brand>
                    <Button
                        variant={"light"}
                        type="button"
                        className="ml-auto"
                        onClick={logout}
                    >
                        Log Out
                        </Button>
                </Navbar>
                <Approvals 
                    user={props.user}
                ></Approvals>
            </div>
        )
    }

export default Dashboard;