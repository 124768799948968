import React from "react";
import axios from 'axios';
import {
    Row,
    Container,
    Image,
    Button,
    Table,
    Toast,
    Card
} from 'react-bootstrap';

import RefreshIcon from '../assets/Refresh-Icon.svg';

import '../styles/table.css';

class Approvals extends React.Component {

    state = {
        loading: false,
        errorLoading: false,
        approvalOrders: [],
        checkedRows: [],
        showConfirm: false,
        processingApproval: false,
        showApprovalConfirmation: false,
        approvalProcessedSuccess: null
    };

    constructor(props) {
        super(props);
        this.selectAll = React.createRef();
        this.refreshIcon = React.createRef();
    };

    loadApprovalRows = () => {
        this.setState({loading: true, errorLoading: false});
        this.refreshIcon.current.classList.add('img-spin');
        axios.get('/api/API-Server', {
            params: {
                DIDToken: this.props.user.DIDToken,
                requestType: 'load-data'
            }
        })
            .then(res => {
                this.setState({approvalOrders: res.data, loading: false, errorLoading: false})
                this.refreshIcon.current.classList.remove('img-spin');
            })
            .catch(error => {
                if(error.response.data.code === "ERROR_DIDT_EXPIRED"){
                    this.props.logoutUser(true);
                }
                this.setState({loading: false, errorLoading: true});
                this.refreshIcon.current.classList.remove('img-spin');
                this.uncheckAll();
            })
    };

    componentDidMount() {
        this.loadApprovalRows();
    };
    
    checkedRow = (itemId) => {
        let newArray = [...this.state.checkedRows, itemId];
        if(this.state.checkedRows.includes(itemId)){
            newArray = newArray.filter(item => item !== itemId);
        }
        this.setState({
            checkedRows: newArray
        })
    };

    refreshApprovalTable = () => {
        this.loadApprovalRows();
    };

    toggleAll = (e) => {
        let checkboxes = document.getElementsByClassName('row-checkbox');
        if(this.selectAll.current.checked){
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
            };
            let allChecked = Array.prototype.slice.call(checkboxes).map(checkbox => {
                return checkbox.name
            })
            this.setState({checkedRows: [...allChecked]})
        }
        else{
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            };
            this.setState({checkedRows: []})
        }
    };

    uncheckAll = () => {
        let checkboxes = document.getElementsByClassName('row-checkbox');
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        };
    }

    approvalRows = (array) => {
        return array.map(item =>
            <tr key={item.RowKey} id={item.RowKey} >
                <td className="align-middle">
                    <div>
                        <input name={item.RowKey} className="row-checkbox" onChange={() => this.checkedRow(item.RowKey)}  type="checkbox"></input>
                    </div>
                </td>
                <td className="align-middle">{item.sfdcId}</td>
                <td className="align-middle">{item.firstName}</td>
                <td className="align-middle">{item.lastName}</td>
                <td className="align-middle">{item.email}</td>
                <td className="align-middle">{item.repFirstName}</td>
                <td className="align-middle">{item.repLastName}</td>
                <td className="align-middle">{item.repEmail}</td>
                <td className="align-middle">{item.productName}</td>
                <td className="align-middle">{item.pacify}</td>
                <td className="align-middle"><a href={item.pdfUrl} target="blank">PDF</a></td> {/** PDF From proofer */}
            </tr>
        );
    };

    showConfirm = (action) => {
        this.setState({showConfirm : true, action: action})
    };

    confirmToast = () => {
        return (
            <div>
                <Toast.Header>
                    Confirm Action
                </Toast.Header>
                <Toast.Body>
                    {(this.state.checkedRows.length > 0) ?
                        <p>Are you sure you'd like to {String(this.state.action)} {this.state.checkedRows.length} {this.state.checkedRows.length > 1 ? 'orders': 'order'}</p> :
                        <p>Please select some orders to approve or reject</p>
                    }
                    {(this.state.checkedRows.length > 0) ?
                    <Button variant={String(this.state.action) === "approve" ? "success" : "danger"} onClick={() => {this.sendApproval()}} disabled={this.state.checkedRows.length < 1 ? true : false}>Yes, {String(this.state.action)} {this.state.checkedRows.length} {String(this.state.action) === "approve" ? '🚀' : '🗑️'} </Button> :
                    null
                    }       
                    {this.state.processingApproval ? <p className="mt-2">Processing, please wait...</p> : null}
                </Toast.Body>
            </div>
        )
    };

    sendApproval = () => {
        this.setState({processingApproval: true});
        axios.post('https://prod-122.westus.logic.azure.com:443/workflows/42b49ed5136f4d6c85a02cca4ddb858b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-iB0vYq8vSmRPCqVzFLSFmsgNh7KnEf6vs2d7LpE14U', {
            rows: this.state.checkedRows,
            action: this.state.action
        })
        .then(response => {
            this.setState({showConfirm: false, processingApproval: false, showApprovalConfirmation:true, approvalProcessedSuccess: true, checkedRows: [], action:null});
            this.loadApprovalRows();
        })
        .catch(error => {
            this.setState({showConfirm: false, processingApproval: false, showApprovalConfirmation:true, approvalProcessedSuccess: false, checkedRows: [], action:null});
        })
    };

    tableStatus = () => {
        return (
            <p className="lead">
                {this.state.loading ? 'Loading...' : null}
                {this.state.errorLoading ? 'We encountered an error fetching new data, please try again'  : null}
                {!this.state.errorLoading && !this.state.loading && (this.state.approvalOrders.length < 1) ? 'No orders found ✔️' : null}
            </p>
        )
    };


render() {
    return (
        <Card className="m-3 border-0 shadow-sm">
            <Container fluid={true}>
                <Row className="w-100 mx-auto my-3">
                    <Container fluid={true}>
                        <h3 className="mt-3">
                            Pending Approval
                            <Button variant="light" type="button" onClick={() => this.refreshApprovalTable()} className="border-0" style={{background: '#fff'}}><Image ref={this.refreshIcon} src={RefreshIcon}></Image></Button>
                            <br></br>
                            <span className="text-muted h5">
                                <small>Select All <input type="checkbox" ref={this.selectAll} onChange={this.toggleAll}></input></small>
                            </span>
                        </h3>
                    </Container>
                    <Container fluid={true}>
                        <Table  className="table-responsive p-3" borderless={true}>
                            <thead >
                                <tr style={{width: '600px'}}>
                                    <th>
                                        🎁
                                    </th>
                                    <th style={{width: '100px', minWidth: '100px'}}>SFDC ID</th>
                                    <th style={{width: '150px', minWidth: '150px'}}>First Name</th>
                                    <th style={{width: '150px', minWidth: '150px'}}>Last Name</th>
                                    <th style={{width: '100px', minWidth: '100px'}}>Email</th>
                                    <th style={{width: '150px', minWidth: '150px'}} >Rep First</th>
                                    <th style={{width: '150px', minWidth: '150px'}} >Rep Last</th>
                                    <th style={{width: '150px', minWidth: '150px'}}>Rep Email</th>
                                    <th style={{width: '200px', minWidth: '200px'}}>Product</th>
                                    <th style={{width: '100px', minWidth: '100px'}}>Is PAC</th>
                                    <th style={{width: '100px', minWidth: '100px'}}>Proof</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.approvalOrders.length > 0 ? this.approvalRows(this.state.approvalOrders) : null}
                            </tbody>
                        </Table>
                    </Container>
                    <Container fluid={true}>
                        <Button className="mx-1 my-1" disabled={this.state.processingApproval ? true : false} variant="success" type="button" onClick={() => this.showConfirm('approve')}>Approve 🎉</Button>
                        <Button className="mx-1 my-1" disabled={this.state.processingApproval ? true : false} variant="danger" type="button" onClick={() => this.showConfirm('reject')}>Reject 🙅</Button>
                    </Container>
                    <Container className="mt-3" fluid={true}>
                        {this.tableStatus()}
                    </Container>
                    <Container fluid={true} className="my-2">
                        <Toast show={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} animation={true}>
                            {this.confirmToast()}
                        </Toast>
                        <Toast show={this.state.showApprovalConfirmation} animation={true} onClose={() => this.setState({showApprovalConfirmation: false, approvalProcessedSuccess: null})}>
                            <Toast.Header>
                                {this.state.approvalProcessedSuccess ?
                                    'Your request was processed successfully' :
                                    'Your request was not processed successfully'
                                }
                            </Toast.Header>
                            <Toast.Body>
                                {this.state.approvalProcessedSuccess === true ?
                                    'Feel free to close this prompt' :
                                    null
                                }
                                {this.state.approvalProcessedSuccess === false ?
                                    'Please try again' :
                                    null
                                }  
                            </Toast.Body>
                        </Toast>
                    </Container>
                </Row>
            </Container>
        </Card>
    );
}
}



export default Approvals;