import { Container, Row, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Bug from '../assets/UndrawBug.svg'



function FourOhFour() {
    let history = useHistory();
    const redirect = () => {
        history.push('/Login')
    }
    return (
        <Row className="h-100 d-flex flex-column mx-auto w-100" >
            <Container className="my-auto text-center p-4">
                <h1 className="mb-5 mx-auto">Page Not Found</h1>
                <Image src={Bug} className="img-fluid"></Image>
                <h3 className="mt-4">The URL may be misspelled or the page you're looking for is no longer available.</h3>
                <Button 
                    variant="primary" 
                    type="submit"
                    style={{padding: '15px', fontSize : 'larger',  maxWidth:350, borderRadius: '.6rem', borderTopRightRadius: 0, borderBottomLeftRadius: 0, background: 'linear-gradient(to right, #21dbaa,#00b4ef,#0768dd,#5f1ae5)', border: 'none'}}
                    className="w-100 my-3"
                    onClick={redirect}    
                >
                    Return Home
                </Button>
            </Container>
        </Row>
    );
}


export default FourOhFour;