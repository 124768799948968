import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { checkUser, isLoggedIn } from './services/magic.js';

import Login from './components/Login.js';
import FourOhFour from './components/FourOhFour.js';
import Dashboard from './components/Dashboard.js';

function App() {

  const [user, setUser] = useState({ isLoggedIn: null, email: '', DIDToken:'' }),//User object contains DID token which is necessary for API
    [loggingIn, setLoggingIn] = useState();


  const validateUser = async () => {//Validates a user and sets state
    try {
      await checkUser(setUser);
      setLoggingIn(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {//Run use effect to validate user only on mount
    isLoggedIn().then((res) => {
      if(res === true){
        setLoggingIn(true);
        validateUser();
      };
    })
  }, []);


  return (
    <div className="App h-100">
      <Router>
        <Switch>
          <Route path={["/Login"]}>
            {user.isLoggedIn === false || user.isLoggedIn === null ? 
              <Login 
                user={user}
                validateUser={validateUser}
                loggingIn={loggingIn}
              ></Login>
              : <Redirect to="/Dashboard"></Redirect>
            }
          </Route>
          <Route path="/Dashboard">
            {user.isLoggedIn === true && user.DIDToken ? 
              <Dashboard
                setUser={setUser}
                user={user}
              ></Dashboard>
              : <Redirect to="/Login"></Redirect>
            }
          </Route>
          <Route component={FourOhFour}></Route>
        </Switch>
      </Router >
    </div >
  );
}

export default App;
